// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FetchSuspiciousEmailsRequest from "./requests/FetchSuspiciousEmailsRequest.res.js";
import * as UpdateSuspiciousEmailRequest from "./requests/UpdateSuspiciousEmailRequest.res.js";

var index = FetchSuspiciousEmailsRequest.Dashboard.exec;

var index$1 = FetchSuspiciousEmailsRequest.Dashboard.SuspiciousIp.exec;

var SuspiciousIp = {
  index: index$1
};

var Dashboard = {
  index: index,
  SuspiciousIp: SuspiciousIp
};

var update = UpdateSuspiciousEmailRequest.exec;

export {
  update ,
  Dashboard ,
}
/* FetchSuspiciousEmailsRequest Not a pure module */
