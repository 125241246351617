// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../../libs/Date.res.js";
import * as SuspiciousEmail from "../../../../../models/SuspiciousEmail.res.js";
import * as DashboardTabsCss from "../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTabsHighlight from "../../../../../styleguide/dashboard/components/tabs/components/DashboardTabsHighlight.res.js";
import * as DashboardSuspiciousEmailEditForm from "../form/DashboardSuspiciousEmailEditForm.res.js";

function fromOptionalDate(date, message) {
  if (date === null || date === undefined) {
    return message;
  } else {
    return $$Date.Helpers.formatDate(date);
  }
}

function DashboardSuspiciousEmailStatus(props) {
  var suspiciousEmail = props.suspiciousEmail;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              "Created: " + $$Date.Helpers.formatDate(suspiciousEmail.createdAt),
                              JsxRuntime.jsx(SuspiciousEmail.Badge.IsValid.make, {
                                    isValid: suspiciousEmail.isValid
                                  }),
                              JsxRuntime.jsx(SuspiciousEmail.Badge.Status.make, {
                                    status: SuspiciousEmail.statusFromString(suspiciousEmail.status)
                                  })
                            ],
                            className: DashboardTabsCss.Tab.Section.dateDetails
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Edit Status",
                                    className: DashboardTabsCss.Tab.label
                                  }),
                              JsxRuntime.jsx(DashboardSuspiciousEmailEditForm.make, {
                                    suspiciousEmail: suspiciousEmail
                                  })
                            ],
                            className: DashboardTabsCss.Tab.Section.container
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "History",
                                    className: DashboardTabsCss.Tab.label
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                            background: "Gray",
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    children: "Date Blocked",
                                                    className: DashboardTabsCss.Tab.$$Highlight.label
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: fromOptionalDate(suspiciousEmail.blockedAt, "Not Set"),
                                                    className: DashboardTabsCss.Tab.$$Highlight.data
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    children: "Timeout Reset",
                                                    className: DashboardTabsCss.Tab.$$Highlight.label
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: fromOptionalDate(suspiciousEmail.timeoutAt, "Not Set"),
                                                    className: DashboardTabsCss.Tab.$$Highlight.data
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                            background: "Gray",
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    children: "Invalid Attempts",
                                                    className: DashboardTabsCss.Tab.$$Highlight.label
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: String(suspiciousEmail.invalidAttempts),
                                                    className: DashboardTabsCss.Tab.$$Highlight.data
                                                  })
                                            ]
                                          })
                                    ],
                                    className: DashboardTabsCss.Tab.$$Highlight.highlightsContainer
                                  })
                            ],
                            className: DashboardTabsCss.Tab.Section.container
                          })
                    ],
                    className: DashboardTabsCss.Tab.Section.container
                  }),
              className: DashboardTabsCss.Tab.container
            });
}

var Css;

var Hightlight;

var make = DashboardSuspiciousEmailStatus;

export {
  Css ,
  Hightlight ,
  fromOptionalDate ,
  make ,
}
/* Date Not a pure module */
